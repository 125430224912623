import * as React from "react"
import HeroVideo from '../../videos/PartyCover.mp4'

const VideoBG = () => {
    return (
        <div>
           
           <div className="relative overflow-hidden">
                <div className="absolute inset-0 h-[500px]">
                    <video loop autoPlay muted width="100%">
                        <source 
                            src={HeroVideo}
                            type="video/mp4"
                        />
                        YOur browser does not suppor thie video tag.
                    </video>
                </div>
                <div className="relative bg-black bg-opacity-50 p-6 h-[500px] flex items-center">
                    <div className="container font-thin text-4xl">
                        <h2 className='text-center text-primary mb-3'>The most exclusive secret society in Asia.</h2>
                        <h2 className='text-center text-skin-base'>Build by Swinger for Swingers</h2>
                    </div>
                </div>
            </div>

            <div className='bg-[#111]'>
                <div className="container text-center p-6 md:p-12 md:text-2xl font-thin">
                    <h2>Exclusive &bull; Upscaled &bull; Classy &#x2022; Erotic • Open-Minded</h2>
                </div>
            </div>
            
        </div>
    )
}

export default VideoBG